import { Link } from "gatsby"
import React from "react"
import { BaseBlockContent } from "@utils/richText"
import { BaseBlockContent2 } from "@utils/richTextS"
import { constants } from "../../../constants/constants"

interface BodyRiskProps {
  _rawBody: any
  path: string
  slugs: any[]
}

const BodyRisks = ({ path, _rawBody, slugs }: BodyRiskProps) => {
  const riskPath = path.split("/")[2]
  let auxPath = constants.APP_URL
  return (
    <div className="body-risk-container">
      <BaseBlockContent2 className="risk-content" blocks={_rawBody} />

      <div className="buttons-container">
        <Link to={`/`}>Home</Link>

        {path !== "/risks" ? (
          path.split("/").map((e, index) => {
            auxPath = index === 0 ? auxPath + `${e}` : auxPath + `/${e}`

            return (
              index !== path.split("/").length - 1 && (
                <Link to={auxPath} style={{ marginLeft: "4px" }}>
                  {index != 0 && " | "}
                  {e
                    .substring(0, e.length)
                    .replace(/^\w/, c => c.toUpperCase())}
                </Link>
              )
            )
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default BodyRisks
