import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

interface IRisk {
  title: string
  customImage: {
    image: {
      asset: {
        fluid
        description
      }
    }
  }
}

interface ISlug {
  slug: {
    current
  }
}

interface HeroProps {
  post: IRisk
  slugs: Array<ISlug>
}

const Hero = ({ post, slugs }: HeroProps) => {
  const { title, customImage } = post
  const image = convertToBgImage(getImage(customImage.image.asset?.gatsbyImageData))
  return (
    <BackgroundImage
      Tag="div"
      className="hero-post-container"
      {...image}
      alt={customImage.image.asset?.description}
    >
      <div className="hero-blog-content">
        <h1 className="headline">{title}</h1>
        <span className="blog-description">
          {slugs
            .filter(e => {
              return (
                e.slug && e.slug.current && e.slug.current.split("/").length < 3
              )
            })
            .map((e, index) => {
              const slug = e.slug && e.slug.current
              return (
                slug &&
                slug !== "/" && (
                  <Link key={index} to={`/risks${slug}`}>
                    {`${index !== 0 ? "|" : ""} ${slug
                      .substring(1, slug.length)
                      .replace(/^\w/, c => c.toUpperCase())} `}
                  </Link>
                )
              )
            })}
        </span>
      </div>
    </BackgroundImage>
  )
}

export default Hero
